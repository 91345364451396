import {
  backToTop,
  fbLogo,
  globalLogo,
  halidaLogo,
  itemFirst,
  itemSecond,
  sologanImg,
  ytbLogo,
  itemHeader,
  getReward
} from "../../images/Image";
import AnimationComponent from "../animations/AnimationComponent";
import StoreComponent from "../table-store/StoreComponent";
import styles from "./Body.module.css";
function BodyComponent() {
  return (
    <div className={styles["body"]}>
      <div className={styles["sologan-container"]}><img
        className={styles["sologan"]}
        alt={sologanImg}
        src={sologanImg}
      ></img></div>
      <AnimationComponent urlImageHeader={itemHeader} urlImage1={itemFirst} urlImage2={itemSecond} urlImage3={getReward} />
      <StoreComponent />
      <div className={styles["footer"]}>
        <div className={styles["footer-left"]}>
          <img
            alt={halidaLogo}
            src={halidaLogo}
            className={styles["footer-logo"]}
          />
          <div className={styles["footer-left-content"]}>
            <span className={styles["footer-left-text"]}>
              THEO DÕI CHÚNG TÔI TẠI
            </span>
            <div className={styles["social-group"]}>
              <a href="https://www.facebook.com/HalidaBeer" target="_blank">
                <img
                  alt={fbLogo}
                  src={fbLogo}
                  className={styles["social-logo"]}
                />
              </a>
              <a href="https://carlsbergvietnam.vn/vi/products/halida/halida/?Ckey=34248" target="_blank">
                <img
                  alt={globalLogo}
                  src={globalLogo}
                  className={styles["social-logo"]}
                />
              </a>
              <a href="https://www.youtube.com/@halidabeer9155" target="_blank">
                <img
                  alt={ytbLogo}
                  src={ytbLogo}
                  className={styles["social-logo"]}
                />
              </a>
            </div>
          </div>
        </div>
        <img
          onClick={() => window.scrollTo({ behavior: "smooth", top: 0 })}
          alt={backToTop}
          src={backToTop}
          className={styles["back-to-top-logo"]}
        />
      </div>
    </div>
    
  );
}

export default BodyComponent;
