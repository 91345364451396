const isLogin = () => {
  const userInfo = localStorage.getItem("userInfo");
  
  if (userInfo != null) {
    return true;
  } else {
    return false;
  }
};

const logout = () => { };

module.exports = {
  isLogin,
  logout,
};
