import { memo } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

function ModalComponent({ isShow, setIsShow }) {
  const navigate = useNavigate();
  const handleHide = () => {
    setTimeout(() => {
        navigate("/stores");
        // window.location.reload();
    }, 1000);
    setIsShow(false);
  };
  return (
    <>
      <Modal show={isShow} onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>Halida Beer!!</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", color: "green" }}>
          Logged In successfully!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleHide}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default memo(ModalComponent);
