import { useEffect, useState } from "react";
import {
  btnSearch,
} from "../../images/Image";
import moment from 'moment';
import styles from "./Store.module.css";
import { isMobile } from "react-device-detect";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function StoreComponent() {
  const [stores, setStores] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [citySelect, setCitySelect] = useState();
  const [districtSelect, setDistrictSelect] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${apiUrl}/v1/halida/stores/all`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': '6afbdbc4-87e6-4abd-95b1-433d8994eacd'
          },
          body: JSON.stringify({
            appId: 'microsites.halida.1'
          }),
        });

        if (response.ok) {
          const storesData = await response.json();
          const citiesData = new Set();
          storesData.data.forEach((value) => {
            citiesData.add(value.city);
          });

          storesData.data.sort((a, b) => moment(a.dateTime).unix() - moment(b.dateTime).unix())
          localStorage.setItem("stores", JSON.stringify(storesData.data));

          setStores(storesData.data);
          setCities(citiesData);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const handleSelectCity = (e) => {
    const citiSelected = e.target.value;
    const districtsData = new Set();

    const sotresLocal = JSON.parse(localStorage.getItem("stores")) ?? [];
    sotresLocal.forEach((store) => {
      if (store.city === citiSelected) {
        districtsData.add(store.district);
      }
    });

    setCitySelect(citiSelected);
    setDistricts(districtsData);
  };

  const handleFilter = () => {
    let sotresLocal = JSON.parse(localStorage.getItem("stores")) ?? [];
    let storesData = [];

    if (citySelect !== undefined) {
      storesData = sotresLocal.filter(function (store) {
        return store.city === citySelect;
      });
      setDistrictSelect(undefined)
    }

    if (districtSelect !== undefined) {
      storesData = sotresLocal.filter(function (store) {
        return store.district === districtSelect;
      });
    }

    setStores(storesData);
  }

  return (
    <div className={styles["container"]}>
      <div className={styles["wrapper-select-box"]}>
        <div className={styles["select-box"]}>
          <select className={styles["select-box1"]} onChange={handleSelectCity}>
            <option>TỈNH/THÀNH PHỐ</option>
            {[...cities].map((ci) => (
              <option key={ci} value={ci} defaultValue={citySelect === ci} className={styles["special"]}>
                {ci}
              </option>
            ))}
          </select>

          <div className={styles["line"]}></div>

          <select
            className={styles["select-box2"]}
            onChange={(e) => setDistrictSelect(e.target.value)}
          >
            <option>Quận/Huyện</option>
            {[...districts].map((dist) => (
              <option
                key={dist}
                value={dist}
                defaultValue={districtSelect === dist}
                className={styles["special"]}
              >
                {dist}
              </option>
            ))}
          </select>

          <div className={styles["line"]}></div>

          <div className={styles["filter"]}>
            <img onClick={handleFilter} src={btnSearch}
              className={styles["btn-search"]}></img>
          </div>
        </div>
      </div>

      <div className={styles["table-header"]}>
        <table>
          <thead>
            <tr>
              <th scope="col" width="150">STT</th>
              <th scope="col" width="300">Tên cửa hàng</th>
              <th scope="col">Địa chỉ</th>
              <th scope="col" width="200">Thời gian</th>
            </tr>
          </thead>
        </table>
      </div>

      <div className={styles["table-list"]}>
        <table>
          <tbody>
            {stores.map((store, index) => (
              <tr className={styles["table-row"]} key={store.id}>
                <td>
                  <div className={styles["shadow"]}></div>
                  {isMobile == true ? (
                    <table className={styles["table-gradient"]}>
                      <tbody>
                        <tr className={styles["table-row"]}>
                          <td width="40" className={`${styles["col"]} ${styles["mobile-col-1"]}`}>
                            <label className={styles["stt"]}>{index + 1}</label>
                          </td>
                          <td className={`${styles["col"]} ${styles["mobile-col-2"]}`}>
                            <table>
                              <tbody>
                                <tr className={styles["table-row"]}>
                                  <td width="76" className={`${styles["col"]} ${styles["mobile-col-3"]}`}>
                                    Tên cửa hàng
                                  </td>
                                  <td className={`${styles["col"]} ${styles["mobile-col-4", "mobile-col-4-label"]}`}>
                                    {store.name}
                                  </td>
                                </tr>
                                <tr className={styles["table-row"]}>
                                  <td className={`${styles["col"]} ${styles["mobile-col-3"]}`}>
                                    Địa chỉ
                                  </td>
                                  <td className={`${styles["col"]} ${styles["mobile-col-4"]}`}>
                                    {`${store.street}, ${store.district}, ${store.city}`}
                                  </td>
                                </tr>
                                <tr className={styles["table-row"]}>
                                  <td className={`${styles["col"]} ${styles["mobile-col-3"]}`}>
                                    Thời gian
                                  </td>
                                  <td className={`${styles["col"]} ${styles["mobile-col-4", "mobile-col-4-label-smaller"]}`}>
                                    {`${moment(store.dateTime).format('HH:mm - DD/MM')}`}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <table className={styles["table-gradient"]}>
                      <tbody>
                        <tr className={styles["table-row"]}>
                          <td data-label="STT" width="150" className={`${styles["col"]} ${styles["col-1"]}`}>
                            {index + 1}
                          </td>
                          <td data-label="Tên cửa hàng" width="300" className={`${styles["col"]} ${styles["col-2"]}`}>
                            {store.name}
                          </td>
                          <td data-label="Địa chỉ" className={`${styles["col"]} ${styles["col-3"]}`}>
                            {`${store.street}, ${store.district}, ${store.city}`}
                          </td>
                          <td data-label="Thời gian" width="200" className={`${styles["col"]} ${styles["col-4"]}`}>
                            {`${moment(store.dateTime).format('HH:mm - DD/MM')}`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StoreComponent;