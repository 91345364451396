import "./App.css";
import CreateUpdateComponent from "./components/admin/CreateUpdateComponent";
import ListStoreComponent from "./components/admin/ListStoreComponent";
import LoginComponent from "./components/admin/LoginComponent";
import BodyComponent from "./components/body/BodyComponent";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import { isLogin } from "./utils/checkLogin";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginComponent />} />
          <Route path="/" element={<BodyComponent />} />
          <Route path="/uongvuihesangkhoai" element={<BodyComponent />} />
          <Route element={<PrivateRouter />}>
            <Route
              path="/create-update-store"
              element={<CreateUpdateComponent />}
              exact
            />
            <Route path="/stores" element={<ListStoreComponent />} exact />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function PrivateRouter() {
  return isLogin() ? <Outlet /> : <Navigate to="/login" />;
}

// function logout() {
//   setInterval(async () => {
//     const userInfo = JSON.parse(localStorage.getItem("userInfo"));
//     if (userInfo !== null) {
//       try {
//         localStorage.removeItem("userInfo");
//         window.location.reload();
//       } catch (error) {
//         console.error(error);
//       }
//     }
//   }, 100000);
// }

// logout();

export default App;
