import { useEffect, useRef, useState } from "react";
import styles from './Home.module.css';

const AnimationComponent = ({
  urlImage1 = null,
  urlImage2 = null,
  urlImage3 = null,
  size = 300,
}) => {
  const [isAnimation, setIsAnimation] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsAnimation(true);
        } else if (entries[0].boundingClientRect.top > 0) {
          setIsAnimation(false);
        }
      },
      { threshold: 0.5 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref]);

  return (
    <div
      ref={ref}
      className={styles["containerImage"]}
    >
      <div className={styles["headImage"]}>
        {urlImage1 ? (
          <img
            alt={urlImage1}
            src={urlImage1}
            className={`${isAnimation
                ? styles["urlImage1Animation"]
                : styles["urlImage1AnimationReverse"]
              } ${styles["urlImage1"]}`}
          />
        ) : (
          <div
            className={`${isAnimation
                ? styles["urlImage1Animation"]
                : styles["urlImage1AnimationReverse"]
              } ${styles["urlImage1"]}`}
          />
        )}
        {urlImage2 ? (
          <img
            alt={urlImage2}
            src={urlImage2}
            className={`${isAnimation
                ? styles["urlImage2Animation"]
                : styles["urlImage2AnimationReverse"]
              } ${styles["urlImage2"]}`}
          />
        ) : (
          <div
            className={`${isAnimation
                ? styles["urlImage2Animation"]
                : styles["urlImage2AnimationReverse"]
              } ${styles["urlImage2"]}`}
          />
        )}
        {/* </div> */}
        {urlImage3 ? (
          <img
            alt={urlImage3}
            src={urlImage3}
            className={`${isAnimation
                ? styles["urlImage3Animation"]
                : styles["urlImage3AnimationReverse"]
              } ${styles["urlImage3"]}`}
          />
        ) : (
          <div
            className={`${isAnimation
                ? styles["urlImage3Animation"]
                : styles["urlImage3AnimationReverse"]
              } ${styles["urlImage3"]}`}
          />
        )}
      </div>

    </div>
  );
};

export default AnimationComponent;
