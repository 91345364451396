import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function ListStoreComponent() {
  const navigate = useNavigate();
  const [stores, setStores] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${apiUrl}/v1/halida/stores/all`);
        if (response.ok) {
          const storesData = await response.json();
          setStores(storesData.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const logout = async (store) => {
    localStorage.removeItem("userInfo");
    navigate("/login");
  };

  const handleDelete = async (store) => {
    try {
      const submit = await fetch(
        `${apiUrl}/v1/halida/stores/${store.id}/delete`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Api-Key": "6afbdbc4-87e6-4abd-95b1-433d8994eacd",
          },
        }
      );
      const response = await submit.json();
      if (response.status === 204 && response.message === "success") {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Container fluid="md">
      <h1>Danh sách cửa hàng</h1>
      <Button
        onClick={() => navigate("/create-update-store")}
        variant="primary"
      >
        Thêm mới
      </Button>{" "}
     <Button
        onClick={() => {
          logout();
        }}
        variant="secondary"
      >
        Thoát
      </Button>
      <Table className="mt-4" striped bordered hover>
        <thead>
          <tr>
            <th>STT</th>
            <th>Tên cửa hàng</th>
            <th>Địa chỉ</th>
            <th>Thời gian</th>
            <th>Tuỳ chọn</th>
          </tr>
        </thead>
        <tbody>
          {stores.map((store, index) => (
            <tr key={store.id}>
              <td>{index + 1}</td>
              <td>{store.name}</td>
              <td>{`${store.street}, ${store.district}, ${store.city}`}</td>
              <td>{`${store.dateTime}`}</td>
              <td>
                <Button variant="warning">
                  <Link to={"/create-update-store"} state={{ store: store }}>
                    Sửa
                  </Link>
                </Button>{" "}
                <Button
                  onClick={() => {
                    handleDelete(store);
                  }}
                  variant="danger"
                >
                  Xoá
                </Button>{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default ListStoreComponent;
