const sologanImg = require("../assets/img/sologan.png");
const infoAddressImg = require("../assets/img/info-address.png");
const itemsImg = require("../assets/img/items.png");
const itemFirst = require("../assets/img/item-first.png");
const itemSecond = require("../assets/img/item-second.png");
const halidaLogo = require("../assets/img/halida-logo.png");
const fbLogo = require("../assets/img/fb.png");
const ytbLogo = require("../assets/img/ytb.png");
const globalLogo = require("../assets/img/global.png");
const backToTop = require("../assets/img/back-to-top.png");
const btnSearch = require("../assets/img/search.png");
const itemFirstNew =  require("../assets/img/item-first-new.png");
const itemSecondNew =  require("../assets/img/item-second-new.png");
const itemHeader =  require("../assets/img/item-header.png");
const getReward =  require("../assets/img/Typo_coHoiNhanQua.png");

export {
  backToTop,
  fbLogo,
  globalLogo,
  halidaLogo,
  infoAddressImg,
  itemFirst,
  itemSecond,
  itemsImg,
  sologanImg,
  ytbLogo,
  btnSearch,
  itemFirstNew,
  itemSecondNew,
  itemHeader,
  getReward
};
