import React, { useState } from "react";
import styles from "./Login.module.css";
import ModalComponent from "./ModalComponent";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function LoginComponent() {
  const [fromLogin, setFromLogin] = useState({
    username: "",
    password: "",
  });

  const [passwordError, setpasswordError] = useState("");
  const [emailError, setemailError] = useState("");
  const [loginError, setloginError] = useState("");
  const [isLogedIn, setIsLogedIn] = useState(false);

  const handleValidation = () => {
    let formIsValid = true;

    if (!fromLogin.username.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      formIsValid = false;
      setemailError("Email Not Valid");
      return false;
    } else {
      setemailError("");
      formIsValid = true;
    }

    if (fromLogin.password === "") {
      formIsValid = false;
      setpasswordError("Please enter your password");
      return false;
    } else {
      setpasswordError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = handleValidation();

    if (isValid) {
      try {
        const submit = await fetch('https://id.typing.vn/api/v3/utilities/login', {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(fromLogin),
        });

        const response = await submit.json();
        if (response.status.code === 'ok') {
          localStorage.setItem("userInfo", JSON.stringify(response.response));
          setIsLogedIn(true);
        } else {
          setloginError('Đăng nhập thất bại!');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  
  return (
    <div className={styles["body"]}>
      <div className="container">
        <div className="d-flex justify-content-center align-items-center h-100">
          <div col="12">
            <div
              className="bg-white my-5 mx-auto"
              style={{ borderRadius: "1rem", maxWidth: "500px" }}
            >
              <form
                className="p-5 w-100 d-flex flex-column"
                onSubmit={handleSubmit}
              >
                <h2 className="fw-bold mb-2 text-center">Sign in</h2>
                <p className="text-black-50 mb-3">
                  Please enter your login and password!
                </p>
                <div className="mb-3 form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    onChange={(event) =>
                      setFromLogin({ ...fromLogin, username: event.target.value })
                    }
                  />
                  <small id="emailHelp" className="text-danger form-text">
                    {emailError}
                  </small>
                </div>
                <div className="mb-3 form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter password"
                    onChange={(event) =>
                      setFromLogin({
                        ...fromLogin,
                        password: event.target.value,
                      })
                    }
                  />
                  <small id="passwordHelp" className="text-danger form-text">
                    {passwordError}
                  </small>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
                <small id="passwordHelp" className="text-danger form-text">
                  {loginError}
                </small>
              </form>
            </div>
          </div>
          <ModalComponent isShow={isLogedIn} setIsShow={setIsLogedIn} />
        </div>
      </div>
    </div>
  );
}

export default LoginComponent;
