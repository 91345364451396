import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment';

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

function CreateUpdateComponent(props) {
    const navigate = useNavigate();
    let { state } = useLocation();
    const [inputs, setInputs] = useState({
        name: state !== null ? state.store.name : '',
        street: state !== null ? state.store.street : '',
        district: state !== null ? state.store.district : '',
        city: state !== null ? state.store.city : '',
        dateTime: state !== null ? state.store.dateTime : '',
        appId: "microsites.halida.1"
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (state !== null) {
                const submit = await fetch(`${apiUrl}/v1/halida/stores/${state.store.id}/edit`, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-Api-Key': '6afbdbc4-87e6-4abd-95b1-433d8994eacd'
                    },
                    body: JSON.stringify(inputs)
                })
                const response = await submit.json();
                if (response.status === 200 && response.message === "success") {
                    return navigate("/stores")
                }
            } else {
                const submit = await fetch(`${apiUrl}/v1/halida/stores/add`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-Api-Key': '6afbdbc4-87e6-4abd-95b1-433d8994eacd'
                    },
                    body: JSON.stringify(inputs)
                })
                const response = await submit.json();
                if (response.status === 200 && response.message === "success") {
                    return navigate("/stores")
                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Container fluid="md">
            <h1>Thông tin cửa hàng</h1>
            <Form>
                <Form.Group className="mb-3" controlId="formStore">
                    <Form.Label>Tên cửa hàng</Form.Label>
                    <Form.Control type="text" placeholder="Nhập tên cửa hàng"
                        value={inputs.name} onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formStreet">
                    <Form.Label>Tên đường</Form.Label>
                    <Form.Control type="text" placeholder="Nhập tên đường"
                        value={inputs.street} onChange={(e) => setInputs({ ...inputs, street: e.target.value })}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formDistrict">
                    <Form.Label>Tên Quận / Huyện</Form.Label>
                    <Form.Control type="text" placeholder="Nhập Quận / Huyện"
                        value={inputs.district} onChange={(e) => setInputs({ ...inputs, district: e.target.value })}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCity">
                    <Form.Label>Tỉnh / Thành phố</Form.Label>
                    <Form.Control type="text" placeholder="Nhập Tỉnh / Thành phố"
                        value={inputs.city} onChange={(e) => setInputs({ ...inputs, city: e.target.value })}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formTime">
                    <Form.Label>Thời gian</Form.Label>
                    <Form.Control type="datetime-local" placeholder="Chọn thời gian"
                        value={inputs.dateTime} onChange={(e) => setInputs({ ...inputs, dateTime: moment(e.target.value).format('YYYY-MM-DD HH:mm')})}
                    />
                </Form.Group>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form>
        </Container>

    )
}

export default CreateUpdateComponent;